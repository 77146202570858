@tailwind base;
@tailwind components;
@tailwind utilities;



  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../src/assets/static/OpenSans-Regular.ttf) format('ttf');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../src/assets/static/OpenSans-Bold.ttf) format('ttf');
  }